import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.1.0_@sanity+client@6.22.2_next@14.2.15_@babel+core@7.24.7_@opentelemetr_qureofgngiktcwvt47vnlob7te/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.1.0_@sanity+client@6.22.2_next@14.2.15_@babel+core@7.24.7_@opentelemetr_qureofgngiktcwvt47vnlob7te/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.21.1_next@14.2.15_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1._op7u4fpc7wlxsub4klkru2xzom/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.21.1_next@14.2.15_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1._op7u4fpc7wlxsub4klkru2xzom/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.8.8_@sanity+client@6.22.2_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.62._jqnssjoqtfvhqdyj7wcvhbwo2i/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.7_@opentelemetry+api@1.9.0_@playwright+test@1.46.0_babel-plugin_g3mxtsfqm6j2gwpieykolusls4/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/audio.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/chevron-r.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/amazon-music.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/apple-podcasts.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/pintrest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/spotify.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/x.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/social/youtube.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/assets/icons/video.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/components/audio-player/index.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/badge/badge.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/components/breadcrumbs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ObjectViewerButton"] */ "/vercel/path0/web/components/collection/object-viewer-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/containers/content-split/content-split.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["GTMLink"] */ "/vercel/path0/web/components/controls/links/GTMLink/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/headers/component-header/component-header.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/containers/page-section/page-section.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/components/headers/page-header/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/helper/sanity-image/index.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/markdown-parser/index.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/pt-components.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/marks/callOutBox/callOutBox.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/marks/link/link.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/types/condeNast.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/types/horizontalRule.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/types/sponsorLogoRow.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/types/productTable/productTable.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/types/three-column-table/three-column-table.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/rich-text/pt-components/blocks/blocks.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/containers/grid-container/grid.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/lists/socials-list/socials-list.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/accesibility/redundant-link/redundant-link.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/cards/content-card/content-card.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/components/cards/article-card/article-card.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/components/tab-drawer/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/app/[locale]/(navigation)/perspectives/series/[slug]/series-page.module.scss");
